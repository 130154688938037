import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import spinmanPeace from '../images/spinman-peace.png';
import spinmanPoint from '../images/spinman-point.png';

const StyledInfoContent = styled.section`
	background: ${props => props.theme.darkBlue};
	padding: 0 0 200px;
	position: relative;
	overflow: hidden;
	width: 100%;
	z-index: 1;

	@media (max-width: ${props => props.theme.breakpoints.medium}) {
		padding: 100px 0px 50px;
	}

	&:before {
		content: '';
		height: 900px;
		width: 450px;
		background: radial-gradient(circle, #40918e 0%, #292960 100%);
		opacity: 0.665;
		border-bottom-left-radius: calc(450px * 2);
		border-top-left-radius: calc(450px * 2);
		display: inline-block;
		position: absolute;
		top: 70px;
		right: 0;

		@media (max-width: ${props => props.theme.breakpoints.small}) {
			width: 400px;
			height: 400px;
			top: 75%;
			left: 95%;
			transform: translate(-50%, -50%);
		}
	}

	.wrapper {
		width: 100%;
		max-width: 1200px;
		padding: 0 32px;
		margin: 0 auto;
		align-items: space-between;
		display: flex;
		flex-flow: column;

		&:before {
			content: '';
			height: 600px;
			width: 300px;
			background: radial-gradient(circle, #40918e 0%, #292960 100%);
			opacity: 0.665;
			border-bottom-right-radius: calc(450px * 2);
			border-top-right-radius: calc(450px * 2);
			display: inline-block;
			position: absolute;
			top: 540px;
			left: 0;

			@media (max-width: ${props => props.theme.breakpoints.medium}) {
				content: '';
				position: absolute;
				top: 60px;
				left: -270px;
				width: 500px;
				height: 500px;
				background: radial-gradient(circle, #40918e 0%, #292960 100%);
				opacity: 0.665;
				display: block;
				border-radius: 100%;
				-webkit-transition: all 1s ease;
				transition: all 1s ease;
			}
		}
	}

	.information {
		padding: 0 0 100px 0;
		display: flex;
		justify-content: center;
		position: relative;
		transition: all 0.2s ease;
		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			padding: 50px 0;
			left: 168px;
			height: 500px;
			width: 500px;
			top: 200px;
			text-align: left;

			&.commission {
				left: 500px;
			}
		}

		.info-content {
			max-width: 100%;
			position: relative;
		}

		.header {
			color: ${props => props.theme.blue};
			margin-bottom: 16px;
			font-size: 24px;
			line-height: 1.4;
			@media (min-width: ${props => props.theme.breakpoints.small}) {
				font-size: 32px;
				line-height: 1.2;
				margin-bottom: 24px;
				color: ${props => props.theme.blue};
			}
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
		p {
			color: white;
			opacity: 0.6;
			&.small {
				font-size: 0.8em;
			}
		}

		.spinman-peace {
			position: absolute;
			top: 20px;
			left: 600px;
			width: 80%;
			@media (max-width: ${props => props.theme.breakpoints.medium}) {
				display: none;
			}

			img {
				width: 65%;
				height: auto;
			}
		}

		.spinman-point {
			position: absolute;
			top: -60px;
			right: 480px;
			width: 90%;
			@media (max-width: ${props => props.theme.breakpoints.medium}) {
				display: none;
			}

			img {
				width: 80%;
				height: auto;
			}
		}
	}
`;

function InfoContent() {
	return (
		<StyledInfoContent>
			<div className="wrapper">
				<div className="information" id="information">
					<div className="info-content">
						<h3 className="header">
							<FormattedMessage id="info.whySpinawayHeader" />
						</h3>
						<p className="description">
							<FormattedMessage id="info.whySpinAwayText" />
						</p>
					</div>
					<div className="spinman-peace">
						<img src={spinmanPeace} alt="Spinman Peace" />
					</div>
				</div>
				<div className="information commission" id="commission">
					<div className="spinman-point">
						<img src={spinmanPoint} alt="Spinman Point" />
					</div>
					<div className="info-content">
						<h3 className="header">
							<FormattedMessage id="info.commissionHeader" />
						</h3>
						<p className="description">
							<FormattedMessage id="info.commissionText" />
						</p>
					</div>
				</div>
			</div>
		</StyledInfoContent>
	);
}

export default InfoContent;
