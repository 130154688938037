import React from 'react';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import background from '../images/background.jpg';
import spinman from '../images/spinman-fly.png';
import Button from './Button';

const StyledHero = styled.section`
	width: 100%;
	background-color: ${props => props.theme.darkBlue};
	background-image: url(${background});
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: 4;
	min-height: calc(100vh - 64px);
	display: flex;
	justify-content: center;
	flex-flow: column;

	&:before {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgb(17, 16, 49);
		background: linear-gradient(180deg, rgba(17, 16, 49, 0) 0%, rgba(17, 16, 49, 1) 100%);
		height: 320px;
	}

	.content {
		width: 100%;
		margin: 0 auto;
		max-width: 1400px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		padding: 64px 24px 32px;
		position: relative;
		z-index: 1;
		height: 100%;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			flex-direction: row;
			align-items: center;
			padding: 64px 32px;
		}

		.image {
			display: flex;
			align-items: center;

			img {
				width: 100%;
				height: auto;
				max-width: 132px;
				margin-bottom: 32px;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					max-width: 230px;
					margin-bottom: 0;
				}
			}
		}

		.image,
		.hero-text {
			display: flex;
			flex-direction: column;
			flex-basis: 100%;
			flex: 1;
		}
	}

	h1 {
		font-size: 28px;
		line-height: 1.4;
		margin-bottom: 16px;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			font-size: 48px;
			line-height: 1.2;
		}
	}

	.hero-text {
		color: white;
		display: flex;
		align-items: flex-start;
		margin-top: 32px;
		text-align: center;
		align-items: center;
		margin-bottom: 0;

		p {
			@media (min-width: ${props => props.theme.breakpoints.medium}) {
				width: 500px;
			}
		}

		.buttons {
			display: flex;
			flex-direction: column;
			height: 180px;
			justify-content: space-between;
			@media (min-width: ${props => props.theme.breakpoints.medium}) {
				display: flex;
				justify-content: space-between;
				width: 500px;
				height: auto;
				flex-direction: row;
			}
		}

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			text-align: left;
			align-items: flex-start;
			padding-right: 64px;
			margin-bottom: 0;
		}

		p {
			font-size: 16px;
			margin-bottom: 48px;

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				font-size: 18px;
			}
		}
	}

	.float {
		animation-name: floating;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;

		img {
			width: 100%;
			height: auto;
		}
	}
`;

function Hero({ toggleModal }) {
	return (
		<StyledHero>
			<div className="content" id="top">
				<div className="image float">
					<img src={spinman} alt="Spin Man" />
				</div>
				<div className="hero-text">
					<h1>
						{/* Insert <br /> after "to" */}
						<FormattedMessage id="hero.welcome" />
					</h1>
					<p>
						<a href="https://www.spinaway.com" target="_blank" rel="noopener noreferrer">
							SpinAway
						</a>{' '}
						<FormattedMessage id="hero.welcomeText" />
					</p>
					<div className="buttons">
						<Button primary="true" purple="true" onClick={() => toggleModal()}>
							<FormattedMessage id="hero.buttonRegister" />
						</Button>
						<a href="mailto:affiliates@spinaway.com" target="_blank" rel="noopener noreferrer">
							<Button primary="true" className="button">
								<FormattedMessage id="hero.buttonContact" />
							</Button>
						</a>
					</div>
				</div>
			</div>
		</StyledHero>
	);
}

export default Hero;
