import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import GlobalStyles from './components/GlobalStyles';
import theme from './styles/theme';
import App from './components/App';
import TermsAndConditions from './components/terms-and-conditions';
import Wrapper from './components/Wrapper';
import TermsAndConditionsOntario from './components/terms-and-conditions-ontario';


ReactDOM.render(
	// <React.StrictMode>
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<ScrollToTop />
			<Wrapper>
				<Header />
				<Switch>
					<Route exact path="/" component={App} />
					<Route path="/terms-and-conditions" component={TermsAndConditions} />
					<Route path="/terms-and-conditions-ontario" component={TermsAndConditionsOntario} />
					<Redirect to="/" />
				</Switch>
			</Wrapper>
			<GlobalStyles />
		</ThemeProvider>
	</BrowserRouter>,
	// </React.StrictMode>,
	document.getElementById('root')
);
