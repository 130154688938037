import React from 'react';
import styled from 'styled-components';
import CloseIcon from './CloseIcon';

const StyledCloseButton = styled.button`
	position: relative;
	top: 0;
	font-size: 1em;
	padding: 6px;
	display: inline-flex;
	z-index: 2;
	cursor: pointer;
	color: black;
`;

function CloseButton() {
	return (
		<StyledCloseButton>
			<CloseIcon />
		</StyledCloseButton>
	);
}

export default CloseButton;
