/* eslint-disable */
import React from "react";
import styled from "styled-components";

const StyledTestimonial = styled.li`
  display: flex;
  align-items: center;
  border: 2px solid ${(props) => props.theme.purple};
  border-radius: 24px;
  margin: 0;
  margin-right: 32px;
  list-style: none;
  position: relative;
  padding: 2em;
  width: 310px;
  margin-left: 32px;
  margin-right: 32px;
  height: fit-content;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 3em;
    margin-right: 64px;
    margin-left: 0;
    min-width: 340px;
  }

  .blockquote {
    color: ${(props) => props.theme.blue};
    margin: 0;
    p {
      font-size: 14px;
      line-height: 1.6;
      text-align: left;
      text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
      white-space: break-spaces;

      @media (min-width: ${(props) => props.theme.breakpoints.small}) {
        font-size: 16px;
      }
    }
  }

  .quoter {
    position: relative;
    color: ${(props) => props.theme.white};
    opacity: 0.8;
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.breakpoints.small}) {
      font-size: 16px;
    }

    &:hover {
      color: ${(props) => props.theme.blue};
      text-decoration: underline;
      cursor: pointer;
    }
    &.no-link:hover {
      text-decoration: none;
      cursor: default;
      color: ${(props) => props.theme.white};
    }
  }
`;

const Testimonial = ({ quote, quoter, link = "" }) => {
  return (
    <StyledTestimonial>
      <blockquote className="blockquote">
        <p>{quote}</p>
        {link !== "" ? (
          <a href={link} target="_blank" className="quoter">
            {quoter}
          </a>
        ) : (
          <span className="quoter no-link">{quoter}</span>
        )}
      </blockquote>
    </StyledTestimonial>
  );
};

export default Testimonial;
