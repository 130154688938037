import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Close from './CloseButton';
import { Context } from './Wrapper';

const StyledModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	z-index: 9999;
	overflow-y: auto;
	padding: 64px 32px;

	.column1 {
		display: none;
	}

	.modal-container {
		background: white;
		width: 100%;
		max-width: 640px;
		height: auto;
		padding: 0;
		border-radius: 8px;
		position: relative;

		.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 10px;
			border: none;
			font-size: 14px;
			font-weight: bold;
			cursor: pointer;
			outline: none;
			border-radius: 8px;
			transition: background-color 0.2s ease;
			background: none;
			position: relative;
			top: 0;
			font-size: 1.8em;
			padding: 6px;
			display: inline-flex;
			z-index: 2;

			&:hover {
				background-color: #edf2f7;
			}
		}

		.text {
			padding: 32px 32px 16px;
			text-align: center;

			h2 {
				font-size: 32px;
				color: black;
			}

			p {
				margin-bottom: 0;
				color: black;
			}
		}
	}
`;

const Modal = ({ toggleModal }) => {
	const context = useContext(Context);
	const { locale } = context;

	const url =
		locale === 'th'
			? 'https://affiliates.spinawaypartners.com/signup.html?l=169'
			: 'https://affiliates.spinawaypartners.com/signup.html';

	return (
		<StyledModal>
			<div className="modal-container">
				<button className="close" type="button" onClick={() => toggleModal()}>
					<Close />
				</button>
				<div className="text">
					<h2>
						<FormattedMessage id="registrationModal.header" />
					</h2>
					<p>
						<FormattedMessage id="registrationModal.subHeader" />
					</p>
				</div>
				<iframe
					title="signup"
					src={url}
					style={{ border: 'none' }}
					name="spinawaypartnersiFrame"
					scrolling="auto"
					frameBorder="0"
					marginHeight="0px"
					marginWidth="0px"
					height="600px"
					width="100%"
					allowFullScreen="no"
				/>
			</div>
		</StyledModal>
	);
};

export default Modal;
