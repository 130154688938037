import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
   ${reset}

   body, html {
      font-size: 16px;
      font-family: 'Noto Sans', sans-serif;
      color: ${props => props.theme.white};
      font-weight: 400;
      background: ${props => props.theme.darkBlue};
      overflow-x: hidden;

      &.drawer-open, &.ReactModal__Body--open {
         overflow: hidden;
      }

      &.fixed-nav {
         padding-left: 300px;
      }
   }

   * {
      box-sizing: border-box;

      &:focus {
         outline: none;
      }
   }

   h1, h2, h3, h4, h5 {
      font-family: 'Ubuntu', sans-serif;
      font-weight: 700;
   }

   h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
   }

   select, option, a, button {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      font-size: 1em;
      font-family: 'Ubuntu', sans-serif;
      font-weight: 500;
      line-height: 1em;
      white-space: nowrap;
      border: none;
      background: none;
      padding: 0;
      transition: all .2s ease;
   }

   .ubuntu {
      font-family: 'Ubuntu', sans-serif;
   }

   p {
      color: rgba(255,255,255,.8);
      line-height: 1.6;
      margin-bottom: 1em;
   }

   ul.styled {
      margin-top: 16px;
      margin-bottom: 16px;
      list-style: none;
      padding-left: 24px;

      li {
         margin-bottom: 16px;
         line-height: 1.6;
         position: relative;
         color: rgba(255,255,255,.8);

         &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 10px;
            border-color: transparent transparent transparent ${props => props.theme.yellow};
            left: -24px;
            top: 5px;
            position: absolute;
         }

         > p, button, a {
            display: inline;
         }

         p:last-child {
            margin-bottom: 0;
         }

         button, a {
            color: ${props => props.theme.green};
            margin-left: 8px;
         }
      }
   }

   strong {
      font-weight: 600;
   }

   em {
      font-style: italic;
   }

  aside {
		margin-top: 128px;
		width: 300px;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		padding: 0 32px 64px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
			display: none;
		}

		a {
			font-size: 14px;
			padding: 2em;
			display: block;
			background: rgba(42, 40, 87, 0.4);
			margin-bottom: 2em;
			border-radius: 24px;
			padding-left: 70px;
			position: relative;

			svg {
				position: absolute;
				top: 50%;
				left: 24px;
				width: 24px;
				height: auto;
				transform: translateY(-50%);
				opacity: 0.6;
			}

			&.logout {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				color: ${props => props.theme.purple};
				background: none;
				margin-bottom: 0;

				&:hover {
					background: none;
				}
			}

			&:hover,
			&.active {
				background: ${props => props.theme.backgroundPurple};
				color: ${props => props.theme.blue};
			}
		}
	}

   .spacer {
      display: block;
   }

   .capitalize {
      text-transform: capitalize;
   }

   .slide-left-appear,
	.slide-right-appear,
	.fade-appear,
	.fade-enter {
		opacity: 0;
	}

	.slide-left-appear-active,
	.slide-right-appear-active,
	.fade-appear-active,
	.fade-enter-active {
		opacity: 1;
		transition: opacity 400ms;
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit-active {
		opacity: 0;
		transition: opacity 400ms;
	}

	.slide-left-exit {
		transform: translateX(0);
		opacity: 1;
	}

	.slide-left-exit-active {
		transform: translateX(-100%);
		opacity: 0;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-left-enter {
		transform: translateX(100%);
		opacity: 0;
	}

	.slide-left-enter-active {
		transform: translateX(0);
		opacity: 1;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-right-exit {
		transform: translateX(0);
		opacity: 1;
	}

	.slide-right-exit-active {
		transform: translateX(100%);
		opacity: 0;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-right-enter {
		transform: translateX(-100%);
		opacity: 0;
	}

	.slide-right-enter-active {
		transform: translateX(0);
		opacity: 1;
		transition: transform 400ms, opacity 400ms;
	}


	.slide-down-enter {
		height: 0;
		opacity: 0;
	}

	.slide-down-enter-active {
		height: 461px;
		opacity: 1;
		transition: height 200ms, opacity 200ms;
	}

	.slide-down-exit {
		height: 461px;
		opacity: 1;
	}

	.slide-down-exit-active {
		height: 0;
		opacity: 0;
		transition: height 200ms, opacity 200ms;
	}

	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 1;
		transition: opacity 400ms;
	}

   /* Animations */
   @keyframes floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 15px); }
      100%   { transform: translate(0, -0px); }
   }
`;

export default GlobalStyles;
