const theme = {
	blue: '#60DDCB',
	blueHover: '#40918E',
	darkBlue: '#111031',
	purple: '#92278F',
	purpleHover: '#471346',
	darkPurple: '#1D081C',
	backgroundPurple: 'rgba(42, 40, 87)',
	white: '#fff'
};

theme.breakpoints = {
	small: '767px',
	medium: '1080px'
};

export default theme;
