import React, { useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as Earth } from '../images/earth.svg';
import { ReactComponent as Uk } from '../images/uk.svg';
import { ReactComponent as Th } from '../images/th.svg';
import { Context } from './Wrapper';
import Modal from './modal';

const StyledHeader = styled.header`
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background-color: ${props => props.theme.darkBlue};
	height: 64px;
	align-items: center;
	transition: background-color 0.2s ease;

	.logo {
		padding: 0 16px;
		color: white;
		display: inline-flex;
		width: 120px;

		svg {
			width: 100%;
			height: auto;
		}
	}

	.mobile-nav {
		align-items: center;
		display: flex;

		a,
		button {
			display: inline-flex;
			height: 64px;
			padding: 0 20px 0 0;
			align-items: center;

			svg {
				margin-left: 8px;
			}
		}

		.dropdown {
			button {
				svg {
					padding: 0 0 0 15px;
					width: 80%;
					height: auto;
				}
			}
		}
	}

	.desktop-nav {
		display: none;
	}

	ul {
		display: inline-flex;

		.dropButton {
			color: white;
			border: none;
		}

		.dropdown {
			position: relative;
			display: inline-block;

			.en,
			.th {
				height: 64px;
				width: 94px;
				margin: auto;
			}

			.th {
				border-radius: 0 0 24px 24px;
			}

			button {
				width: 94px;
				height: 64px;
				svg {
					width: 100%;
					height: auto;
					pointer-events: none;
				}
			}

			&:hover .dropContent {
				display: block;
			}
		}

		.dropContent {
			display: none;
			position: absolute;
			width: 100%;
		}

		li:first-child a {
			border-radius: 0 0 0 24px;
			padding-left: 48px;
		}

		a,
		button,
		.bonuses {
			padding: 0 32px;
			display: inline-flex;
			align-items: center;
			color: white;
			background-color: ${props => props.theme.darkBlue};
			height: 64px;
			margin: 0;
			border: none;
			line-height: 2em;

			svg {
				width: 24px;
				height: 24px;
			}

			&:hover {
				color: ${props => props.theme.blue};
			}
		}
	}

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
		background: none;

		.logo {
			width: 172px;
			padding: 20px 32px 0;
		}

		.mobile-nav {
			display: none;
			padding: 0;
		}

		.desktop-nav {
			display: flex;
		}
	}
`;

function Header() {
	const context = useContext(Context);
	const { selectLanguage } = context;
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};
	return (
		<StyledHeader id="header">
			<Link to="/" className="logo">
				{isMobile ? <Logo className="home-icon" /> : <Logo />}
			</Link>
			<nav className="desktop-nav">
				<ul>
					<li>
						<NavLink exact to="/">
							<FormattedMessage id="header.home" />
						</NavLink>
					</li>
					<li>
						<button type="button" className="register" onClick={() => toggleModal()}>
							<FormattedMessage id="header.register" />
						</button>
					</li>
					<li>
						<HashLink smooth to="/#faq">
							<FormattedMessage id="header.faq" />
						</HashLink>
					</li>
					<li>
						<a
							href="https://affiliates.spinawaypartners.com/affiliates"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FormattedMessage id="header.signIn" />
						</a>
					</li>
					<li>
						<div className="dropdown">
							<button type="button" className="dropButton">
								<Earth />
							</button>
							<div className="dropContent">
								<button type="button" className="en" onClick={selectLanguage} value="en">
									<Uk />
								</button>
								<button type="button" className="th" onClick={selectLanguage} value="th">
									<Th />
								</button>
							</div>
						</div>
					</li>
				</ul>
			</nav>
			<nav className="mobile-nav">
				<ul>
					<li>
						<button type="button" className="register" onClick={() => toggleModal()}>
							<FormattedMessage id="header.register" />
						</button>
					</li>
					<li>
						<a
							href="https://affiliates.spinawaypartners.com/affiliates"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FormattedMessage id="header.signIn" />
						</a>
					</li>
					<li>
						<div className="dropdown">
							<button type="button" className="dropButton">
								<Earth />
							</button>
							<div className="dropContent">
								<button type="button" className="en" onClick={selectLanguage} value="en">
									<Uk />
								</button>
							</div>
						</div>
					</li>
				</ul>
			</nav>
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</StyledHeader>
	);
}

export default Header;
