import React from 'react';

import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';

const StyledFaq = styled.section`
	padding: 32px 16px;
	background: ${props => props.theme.backgroundPurple};
	width: 100%;

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
		padding: 128px 360px;
	}

	.wrapper {
		max-width: 900px;
		margin: 0 auto;
		width: 100%;
		position: relative;

		.intro {
			margin-bottom: 48px;
			position: relative;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-flow: row wrap;
			margin-bottom: 48px;
			padding: 0 16px;

			h1 {
				font-size: 32px;
				margin-bottom: 32px;
			}

			p {
				max-width: 600px;
				opacity: 0.6;
				margin-bottom: 32px;
				font-size: 14px;
			}

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				p {
					font-size: 16px;
				}
			}
		}

		p,
		li {
			opacity: 0.6;
			line-height: 1.6;
		}

		ul {
			li {
				line-height: 1.5;
				margin-bottom: 16px;
			}
		}

		.card {
			margin-top: 64px;
			margin-bottom: 32px;
			padding: 64px 32px;
			background: ${props => props.theme.darkBlue};
			border-radius: 32px;

			h2 {
				margin-top: 0;
			}
		}
	}
`;

function Faq() {
	return (
		<StyledFaq id="faq">
			<div className="wrapper">
				<div className="intro">
					<div className="text">
						<h1>
							<FormattedMessage id="header.faq" />
						</h1>
						<p>
							<FormattedMessage id="faq.subheader" />
						</p>
					</div>
				</div>
				<Accordion
					question={<FormattedMessage id="faq.q1" />}
					answer={<FormattedMessage id="faq.a1" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q2" />}
					answer={<FormattedMessage id="faq.a2" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q3" />}
					answer={<FormattedMessage id="faq.a3" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q4" />}
					answer={<FormattedMessage id="faq.a4" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q5" />}
					answer={<FormattedMessage id="faq.a5" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q6" />}
					answer={<FormattedMessage id="faq.a6" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q7" />}
					answer={<FormattedMessage id="faq.a7" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q8" />}
					answer={<FormattedMessage id="faq.a8" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q9" />}
					answer={
						<FormattedMessage
							id="faq.a9"
							defaultMessage="You can find our terms and condition {link}"
							values={{
								link: (
									<Link to="/terms-and-conditions">
										<FormattedMessage id="link" defaultMessage="here" />
									</Link>
								)
							}}
						/>
					}
				/>
			</div>
		</StyledFaq>
	);
}

export default Faq;
