import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
// import { ReactComponent as Logo } from "../images/logo.svg";

const StyledFooter = styled.footer`
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: flex-end;
	padding: 32px;

	.logo {
		padding: 0 16px;
		color: white;
		display: inline-flex;
		width: 120px;

		svg {
			width: 100%;
			height: auto;
		}
	}

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding: 32 32px;
	}

	.row {
		display: flex;
		justify-content: space-between;

		.column {
			display: flex;
			flex-flow: column;
			flex: 1;

			&.nav {
				align-items: flex-end;
				text-align: right;

				p {
					margin-bottom: 0;
					line-height: 1.6;
				}
			}
		}

		.icons {
			padding: 32px 0;
			img {
				height: 32px;
				width: auto;
			}

			svg {
				height: 32px;
				width: 32px;
			}
		}
	}
`;

function Footer() {
	return (
		<StyledFooter id="footer">
			{/* <a href="/" className="logo">
        <Logo />
      </a> */}
			<div className="row">
				<nav className="column nav">
					<ul>
						<li>
							<Link to="/terms-and-conditions">
								<p>
									<FormattedMessage id="footer.termsAndConditions" />
								</p>
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</StyledFooter>
	);
}

export default Footer;
