import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
	font-size: 18px;
	color: ${props => props.theme.blue};
	border: 2px solid ${props => props.theme.blue};
	padding: 1em 3em;
	border-radius: 50vh;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 230px;

	.text {
		transition: transform 0.6s, color 0s;
	}

	.icon {
		position: absolute;
		top: 0;
		right: 16px;
		height: 100%;
		font-size: 42px;
		opacity: 0;
		transition: transform 0.6s, opacity 0.6s;
		transform: translateX(-6px);
	}

	&:hover:not(:disabled) {
		background: ${props => props.theme.blue};
		color: ${props => props.theme.darkBlue};

		.icon {
			opacity: 1;
			transform: translateX(0);
		}

		.text {
			transform: translateX(-6px);
		}
	}

	&:disabled {
		background: ${props => props.theme.greenDisabled};
		cursor: not-allowed;
	}

	${props =>
		props.purple &&
		css`
			background: ${props => props.theme.purple};
			color: ${props => props.theme.white};
			border-color: ${props => props.theme.purple};

			&:hover:not(:disabled) {
				background: ${props => props.theme.purpleHover};
				border-color: ${props => props.theme.purpleHover};
				color: ${props => props.theme.white};
			}

			&:disabled {
				cursor: not-allowed;
				opacity: 0.6;
			}
		`}

	${props =>
		props.primary &&
		css`
			padding: 1.5em 3em;
		`}
`;

function Button({ children, ...props }) {
	return <StyledButton {...props}>{children}</StyledButton>;
}

Button.propTypes = {
	children: PropTypes.any.isRequired
};

export default Button;
