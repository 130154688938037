import React, { useRef, useState, useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { useScroll } from 'react-use';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Testimonial from './testimonial';
import { ReactComponent as Left } from '../images/arrow-left.svg';
import { ReactComponent as Right } from '../images/arrow-right.svg';
import testimonalsData from '../testimonials/data.json';

const StyledTestimonials = styled.section`
	text-align: center;
	padding: 32px 0 0;
	box-sizing: border-box;
	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding: 86px 0 42px;
	}

	.scrollButtons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin: 50px;
		font-size: 30px;
		/* color: ${props => props.theme.purple}; */

		&.hide {
			display: none !important;
		}

		.buttonLeftOpacity {
			opacity: 0.6;
		}

		.buttonRightOpacity {
			opacity: 0.6;
		}
	}

	.content {
		max-width: 100%;
		overflow: auto;
		white-space: nowrap;
		text-align: left;

		scroll-behavior: smooth;
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		/* Hide scrollbar for Chrome, Safari and Opera */

		&::-webkit-scrollbar {
			display: none;
		}

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
       	&:after {
			content: '';
			position: absolute;
			z-index: 1;
         background: rgba(255, 255, 255, 0);
			background-image: linear-gradient(to right, rgba(17, 16, 49, 0), rgba(17, 16, 49, 1) 100%);
         width: 300px;
         height: 482px;
			right: 0;
         pointer-events: none;
		}
	}

		.cards {
			font-family: 'Ubuntu';
			display: inline-flex;
			color: black;
			position: relative;
			margin-right: 20px;

			@media (min-width: ${props => props.theme.breakpoints.medium}) {
				left: 20em;
				padding: 0;
			}
		}
	}

	.text {
		max-width: 100%;
		text-align: left;
		padding: 0 32px;
		margin-bottom: 32px;

		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			max-width: 720px;
			padding: 0 0 0 20em;
		}

		p {
			opacity: 0.8;
		}

		h2 {
			font-size: 32px;
			line-height: 1.2;
			margin-bottom: 16px;
			color: ${props => props.theme.blue};

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				font-size: 48px;
			}
		}
	}
`;

const Testimonials = () => {
	const [show, setShow] = useState(true);
	const scrollRef = useRef(0);
	const { x } = useScroll(scrollRef);
	const [scrollPosition, setscrollPosition] = useState(0);

	const maxWidth = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
	useEffect(() => {
		smoothscroll.polyfill();
		setscrollPosition(x);
	}, [x]);

	const clickHandlerLeft = () => {
		if (x !== 0) {
			document.getElementById('content').scrollBy({ left: -808, behavior: 'smooth' });
		}
	};

	const clickHandlerRight = () => {
		smoothscroll.polyfill();
		document.getElementById('content').scrollBy({ left: +808, behavior: 'smooth' });
	};

	window.addEventListener('touchstart', function userTouch() {
		setShow(false);
	});

	return (
		<StyledTestimonials background="#EDF2F7" id="testimonials">
			<div className="text">
				<h2>
					<FormattedMessage id="testimonials.header" />
				</h2>
				<p>
					<FormattedMessage id="testimonials.text" />
				</p>
			</div>
			{show ? (
				<>
					<div className="scrollButtons" id="scrollButtons">
						<button
							className={scrollPosition === 0 ? 'buttonLeftOpacity' : 'buttonLeft'}
							type="button"
							onMouseDown={() => clickHandlerLeft()}
						>
							<Left className="logo" />
						</button>
						<button
							className={
								scrollPosition === maxWidth && scrollPosition !== 0
									? 'buttonRightOpacity'
									: 'buttonRight'
							}
							type="button"
							onMouseDown={() => clickHandlerRight()}
						>
							<Right />
						</button>
					</div>
				</>
			) : (
				<></>
			)}

			<div className="content" id="content" ref={scrollRef}>
				<ul className="cards">
					{testimonalsData.testimonials.map(item => (
						<Testimonial
							quote={item.quote}
							quoter={item.quoter}
							link={item.link}
							key={item.quoter}
						/>
					))}
				</ul>
			</div>
		</StyledTestimonials>
	);
};

export default Testimonials;
