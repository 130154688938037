import React, { useState } from 'react';

import Modal from './modal';
import Footer from './Footer';
import Hero from './Hero';
import InfoContent from './InfoContent';
import Testimonials from './testimonials';
import Faq from './faq';

function App() {
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};

	return (
		<>
			<Hero toggleModal={toggleModal} />
			<InfoContent />
			<Testimonials toggleModal={toggleModal} />
			<Faq />
			<Footer toggleModal={toggleModal} />
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</>
	);
}

export default App;
